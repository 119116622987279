import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Content, { HTMLContent } from '../components/Content';
import PreviewCompatibleImage from '../components/PreviewCompatibleImage';

export const CardPostTemplate = ({
  content,
  contentComponent,
  description,
  title,
  helmet,
  base_keyword,
  correct_title,
  correct_meaning,
  reverse_title,
  reverse_meaning,
  cardimage
}) => {
  const PostContent = contentComponent || Content;
  console.log(1, cardimage)
  return (
    <section className="section">
      {helmet || ''}
      <div className="container content">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <h1 className="title is-size-2 has-text-weight-bold is-bold-light">
              {title}
            </h1>
            <PostContent content={content} />
            <p>{description}</p>
            <p>{base_keyword}</p>
            <p>{correct_title}</p>
            <p>{correct_meaning}</p>
            <p>{reverse_title}</p>
            <p>{reverse_meaning}</p>
            <PreviewCompatibleImage imageInfo={cardimage} />
          </div>
        </div>
      </div>
    </section>
  );
};

CardPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
  base_keyword: PropTypes.string,
  correct_title: PropTypes.string,
  correct_meaning: PropTypes.string,
  reverse_title: PropTypes.string,
  reverse_meaning: PropTypes.string,
  cardimage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

const CardPost = ({ data }) => {
  const { markdownRemark: post } = data;
  console.log(post)
  return (
    <Layout>
      <CardPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        helmet={
          <Helmet titleTemplate="%s | Fortune">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
        base_keyword={post.frontmatter.base_keyword}
        correct_title={post.frontmatter.correct_title}
        correct_meaning={post.frontmatter.correct_meaning}
        reverse_title={post.frontmatter.reverse_title}
        reverse_meaning={post.frontmatter.reverse_meaning}
        cardimage={post.frontmatter.cardimage}
      />
    </Layout>
  );
};

CardPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default CardPost;

export const pageQuery = graphql`
  query CardPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        base_keyword
        correct_title
        correct_meaning
        reverse_title
        reverse_meaning
        cardimage {
          alt
          image {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;
